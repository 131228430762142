// /**
//  * @description router全局配置，如有必要可分文件抽离，其中asyncRoutes只有在intelligence模式下才会用到，pro版只支持remixIcon图标，具体配置请查看vip群文档
//  */
// import Vue from "vue";
// import VueRouter from "vue-router";
// // import Layout from '@/vab/layouts'
// import { publicPath, routerMode } from "@/config";

// Vue.use(VueRouter);
// export const constantRoutes = [];

// export const asyncRoutes = [
//   {
//     path: "/",
//     name: "workbench",
//     component: () => import("@/views/index/workbench"),
//     meta: {
//       title: "首页",
//       icon: "home-2-line",
//     },
//     children: [
//       {
//         path: "workbench",
//         name: "WorkBench",
//         component: () => import("@/views/index/workbench"),
//         meta: {
//           title: "工作台",
//           icon: "settings-6-line",
//           dot: true,
//         },
//       },
//     ],
//   },
// ];

// const router = createRouter();

// function fatteningRoutes(routes) {
//   return routes.flatMap((route) => {
//     return route.children ? fatteningRoutes(route.children) : route;
//   });
// }

// export function resetRouter(routes = constantRoutes) {
//   routes.map((route) => {
//     if (route.children) {
//       route.children = fatteningRoutes(route.children);
//     }
//   });
//   router.matcher = createRouter(routes).matcher;
// }

// function createRouter(routes = constantRoutes) {
//   return new VueRouter({
//     base: publicPath,
//     mode: routerMode,
//     scrollBehavior: () => ({
//       y: 0,
//     }),
//     routes: routes,
//   });
// }

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch((err) => err);
// };

// export default router;

// 导入 Vue 和 Vue Router
import Vue from "vue";
import Router from "vue-router";
// 导入组件
// import Home from '../components/Home.vue';
// import About from '../components/About.vue';

// 使用 Vue Router
Vue.use(Router);

// 定义路由
const routes = [
  {
    path: "/",
    name: "workbench",
    component: () => import("@/views/index/workbench"),
    meta: {
      title: "首页",
      icon: "home-2-line",
    },
    children: [
      {
        path: "workbench",
        name: "WorkBench",
        component: () => import("@/views/index/workbench"),
        meta: {
          title: "工作台",
          icon: "settings-6-line",
          dot: true,
        },
      },
    ],
  },
];

// 创建路由实例
const router = new Router({
  mode: "hash", // 使用 HTML5 的 history 模式
  routes,
});

// 导出路由实例
export default router;
